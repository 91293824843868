import PageLink from '@/components/page/link';
import StyledImage from '@/components/styledImage';
import { Container, Divider, Grid, Stack, styled, Typography } from '@mui/material';
import useGetDeviceInfo from '../../../hooks/useGetDeviceInfo';
import NavbarLink from '../navbar/navbarLink';
import { footerLinks } from '../routes';

const Footer = styled( 'footer' )( {} );

export default function PublicFooter() {
	const { isCloverDevice } = useGetDeviceInfo();
	if ( isCloverDevice ) return null;
	
	return (
		<Footer
			sx={{
				'bgcolor'                : '#000000',
				'color'                  : '#ffffff',
				'minHeight'              : 200,
				'width'                  : '100%',
				'position'               : 'relative',
				'pt'                     : 5,
				'svg'                    : {
					color: '#ffffff',
				},
				'.FooterLinkCategoryText': {
					color     : '#828282',
					fontWeight: '500',
				},
				'.FooterNavBarLinks'     : {
					'textDecoration': 'none',
					'color'         : '#ffffff',
					'transition'    : '.3s',
					'fontSize'      : '14px !important',
					'width'         : 'max-content',
					':hover'        : {
						color : '#a6a6a6',
						cursor: 'pointer',
					},
				},
			}}>
			<Container sx={{ height: '100%', position: 'relative', pb: 20 }}>
				<Grid container item spacing={1}>
					<Grid item sm xs={12}>
						<PageLink
							href='/'>
							<StyledImage
								alt='logo'
								src='/images/invoiss-logowhite.png'
								sx={{ 'width': 110, ':hover': { cursor: 'pointer' } }}
							/>
						</PageLink>
					</Grid>
					{footerLinks.map( ( link, index ) => (
						<Grid key={index} item sm xs={12}>
							<Typography variant='h6' mb={1} className='FooterLinkCategoryText'>
								{link.name}
							</Typography>
							<Stack spacing={2}>
								{link.routes.map( ( link, index ) => (
									<NavbarLink key={index} href={link.href} className='FooterNavBarLinks'>
										{link.name}
									</NavbarLink>
								) )}
							</Stack>
						</Grid>
					) )}
					{/*<Grid item sm xs={12}>*/}
					{/*	<Typography variant='h6' mb={1} className='FooterLinkCategoryText'>*/}
					{/*		Preference*/}
					{/*	</Typography>*/}
					{/*	<Stack direction='row'>*/}
					{/*		<IconButton>*/}
					{/*		*/}
					{/*		</IconButton>*/}
					{/*		<IconButton>*/}
					{/*		*/}
					{/*		</IconButton>*/}
					{/*		<IconButton>*/}
					{/*		*/}
					{/*		</IconButton>*/}
					{/*	</Stack>*/}
					{/*</Grid>*/}
					<Grid
						item
						xs={12}
						sx={{
							position: 'absolute',
							bottom  : 15,
							right   : 0,
							left    : 0,
						}}>
						<Divider sx={{ my: 1, bgcolor: 'rgba(82,82,82,0.41)' }}/>
						<Stack direction='row' alignItems='center' justifyContent='space-between'>
							<Typography sx={{ color: '#a6a6a6' }}>
								© {new Date().getFullYear()} Invoiss all rights reserved.
							</Typography>
							<Typography sx={{ color: '#a6a6a6' }}>
								United States
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Container>
		</Footer>
	);
}
