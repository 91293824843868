import StyledImage from '@/components/styledImage';
import useUserInfo from '@/providers/auth/useUserInfo';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { Stack, Toolbar, useTheme } from '@mui/material';
import { useState } from 'react';
import MenuButton from './menuButton';
import MenuDropDownContent from './mobileMenuDropDown';
import NavbarButton from './navbarButton';
import NavbarLink from './navbarLink';

export default function MobileContent() {
	const { user } = useUserInfo();
	const theme = useTheme();
	const [ showNavbar, setShowNavbar ] = useState( false );
	
	return (
		<Toolbar variant='dense' sx={{ position: 'relative' }}>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ width: '100%', zIndex: 1600 }}>
				<MenuButton showNavbar={showNavbar} setShowNavbar={setShowNavbar}/>
				<NavbarLink href='/' sx={{ display: 'inline-flex' }}>
					<StyledImage
						width={30}
						height={30}
						src={`/images/invoiss-logo-${theme.palette.mode === 'dark' ? 'light' : 'dark'}.png`}
						alt='Invoiss logo'
					/>
				</NavbarLink>
				<Stack direction='row' alignItems='center' spacing={2}>
					{!user && (
						<NavbarLink href='/login'>Sign in</NavbarLink>
					)}
					{user ? (
						<NavbarButton
							endIcon={<ArrowForwardIcon/>}
							variant='contained'
							color='primary'
							href='/dashboard'>
							Dashboard
						</NavbarButton>
					) : (
						<NavbarButton
							variant='contained'
							color='primary'
							href='/signup'>
							Sign Up
						</NavbarButton>
					)}
				</Stack>
			</Stack>
			<MenuDropDownContent showNavbar={showNavbar} setShowNavbar={setShowNavbar}/>
		</Toolbar>
	);
}
