import type { PageLinkProps } from '@/components/page/link';
import PageLink from '@/components/page/link';

export default function NavbarLink( { linkColor, ...props }: { linkColor?: string } & PageLinkProps ) {
	
	return (
		<PageLink
			{...props}
			sx={{
				'color'         : linkColor || 'text.primary',
				':hover'        : linkColor ? {
					filter: 'brightness(110%)',
					color : linkColor,
				} : {
					color : 'text.secondary',
					cursor: 'pointer',
				},
				'textDecoration': 'none',
				'fontWeight'    : 500,
				'transition'    : '.3s',
				'fontSize'      : 16,
				...props?.sx,
			}}
		/>
	);
}
