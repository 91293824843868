import { Paper, PaperProps, TextField } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import StyledCustomButton, { styledCustomButtonProps } from '../pages/static/common/buttons/staticPageButton';

const validEmailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // Note: Changed to RegExp
export default function EmailSignupForm( {
	size = 'large',
	buttonText,
	StyledButtonProps,
	tag,
	paperProps,
}: {
	size?: 'small' | 'large',
	buttonText?: string,
	StyledButtonProps?: styledCustomButtonProps,
	tag?: string,
	paperProps?: PaperProps
} ) {
	const { enqueueSnackbar } = useSnackbar();
	const [ email, setEmail ] = useState( '' );
	const [ loading, setLoading ] = useState( false );
	
	const handleSubscribeClick = async () => {
		if ( !email || !validEmailRegex.test( email ) ) {
			enqueueSnackbar( 'Please enter a valid email', { variant: 'error' } );
			return;
		}
		try {
			setLoading( true );
			const result = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/mailChimpSubscribe`, {
				email: email,
				tag  : tag,
			} );
			console.log( 'result ui', result );
			enqueueSnackbar( 'Thank you for subscribing!', { variant: 'success' } );
			setLoading( false );
			setEmail( '' );
		} catch ( error ) {
			setLoading( false );
			console.error( error );
			enqueueSnackbar( error?.response?.data || 'There was an error saving your email', { variant: 'error' } );
		}
	};
	return (
		<Paper
			sx={{
				'display'                 : 'flex',
				'alignItems'              : 'center',
				'pl'                      : 3,
				'width'                   : size === 'small' ? { xs: 320, sm: 500 } : { xs: 400, sm: 500 },
				'pr'                      : 1,
				'height'                  : size === 'small' ? 40 : size === 'large'
					? 48.5
					: 70,
				'borderRadius'            : 1,
				'justifyContent'          : 'space-between',
				'bgcolor'                 : '#ffffff',
				'boxShadow'               : 'none',
				'border'                  : 'none',
				'.input:-webkit-autofill' : { color: '#000 !important' },
				'.select:-webkit-autofill': { color: '#000 !important' },
				...paperProps?.sx,
			}}>
			<TextField
				fullWidth
				variant='standard'
				value={email}
				inputProps={{ style: { fontSize: 18 } }}
				InputProps={{ disableUnderline: true }}
				placeholder='Enter your Email'
				onChange={( e ) => setEmail( e.target.value )}
			/>
			<StyledCustomButton
				loading={loading}
				size='small'
				onClick={handleSubscribeClick}
				{...StyledButtonProps}>
				{buttonText || 'Subscribe'}
			</StyledCustomButton>
		</Paper>
	);
}

