import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
import { Box, Collapse, List, ListItem, Stack, styled } from '@mui/material';
import { Fragment, useState } from 'react';
import { navbarLinks } from '../routes';
import NavbarLink from './navbarLink';

const DropDown = styled( 'div' )( {} );

export default function MenuDropDownContent( {
	showNavbar,
	setShowNavbar,
}: {
	showNavbar?: boolean,
	setShowNavbar?: ( value: boolean ) => void
} ) {
	const [ openSubmenu, setOpenSubmenu ] = useState<string | null>( null );
	
	return (
		<Fragment>
			<DropDown
				sx={{
					bgcolor   : 'background.paper',
					transition: 'all .5s',
					boxShadow : 'rgb(0 0 0 / 15%) 0 10px 12px -2px',
					transform : showNavbar ? 'translateY(0)' : 'translateY(-20px)',
					visibility: showNavbar ? 'visible' : 'hidden',
					display   : 'block',
					position  : 'absolute',
					top       : 0,
					left      : 0,
					right     : 0,
					pt        : 6,
					zIndex    : 'tooltip',
					opacity   : showNavbar ? 1 : 0,
				}}>
				{navbarLinks.map( ( link, index ) => (
					<List key={index}>
						<ListItem
							sx={{
								borderTop      : '1px solid rgba(0,0,0,0.05)',
								opacity        : showNavbar ? 1 : 0,
								visibility     : showNavbar ? 'visible' : 'hidden',
								transform      : showNavbar ? 'translateY(0)' : 'translateY(-20px)',
								transition     : 'all .5s',
								py             : 2,
								transitionDelay: `${index * 0.05}s`,
							}}>
							{link.routes ? (
								<Stack
									direction='row'
									alignItems='end'
									spacing={2}
									sx={{
										cursor    : 'pointer',
										color     : 'text.primary',
										fontSize  : 16,
										fontWeight: 400,
									}}
									onClick={() => setOpenSubmenu( ( prev: string ) => prev === link.name ? null : link.name )}>
									{link.name}
									<KeyboardArrowDownRoundedIcon/>
								</Stack>
							) : (
								<NavbarLink
									sx={{
										textDecoration: 'none !important',
										color         : 'text.primary',
										fontSize      : 16,
										fontWeight    : 400,
									}}
									href={link.href}>
									{link.name}
								</NavbarLink>
							)}
						</ListItem>
						
						{link.routes && (
							<Collapse in={openSubmenu === link.name}>
								<List sx={{ bgcolor: 'divider', borderRadius: 2 }}>
									{link.routes.map( ( subRoute, subIndex ) => (
										<ListItem key={subIndex}>
											<NavbarLink href={subRoute.href}>
												{subRoute.name}
											</NavbarLink>
										</ListItem>
									) )}
								</List>
							</Collapse>
						)}
					</List>
				) )}
			</DropDown>
			<Box
				sx={{
					transition: 'all .5s',
					visibility: showNavbar ? 'visible' : 'hidden',
					opacity   : showNavbar ? 1 : 0,
					bgcolor   : 'rgba(0, 0, 0, .5)',
					height    : '100vh',
					position  : 'absolute',
					left      : 0,
					top       : 0,
					right     : 0,
				}}
				onClick={() => setShowNavbar( false )}
			/>
		</Fragment>
	);
}
