import StyledImage from '@/components/styledImage';
import NavbarWebMenu from '@/pages/static/navbar/linkAndMenu';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Box, Stack, Toolbar, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import useGetDeviceInfo from '../../../hooks/useGetDeviceInfo';
import { companyModeIdAtom } from '../../_app.component';
import StyledCustomButton from '../common/buttons/staticPageButton';
import { navbarLinks } from '../routes';
import NavbarLink from './navbarLink';

export default function WebContent() {
	const { user } = useUserInfo();
	const theme = useTheme();
	const { isCloverDevice } = useGetDeviceInfo();
	const companyModeId = useAtomValue( companyModeIdAtom );
	
	const isDarkMode = theme.palette.mode === 'dark';
	if ( companyModeId ) return null;
	
	return (
		<Toolbar variant='regular' sx={{ px: '0 !important', py: 1 }}>
			<Box width='100%'>
				<Stack direction='row' alignItems='center' justifyContent='space-between'>
					<Stack direction='row' spacing={1} alignItems='center'>
						<NavbarLink href='/' sx={{ display: 'inline-flex' }}>
							<StyledImage
								sx={{ height: 26 }}
								src={`/images/invoiss-logo${isDarkMode ? 'white' : 'black'}.png`}
								alt='Invoiss logo'
							/>
						</NavbarLink>
						<Stack direction='row' spacing={.5} alignItems='center'>
							{!isCloverDevice && navbarLinks.map( ( route, index ) => (
								<NavbarWebMenu key={index} route={route}/>
							) )}
						</Stack>
					</Stack>
					<Stack direction='row' alignItems='center' spacing={2}>
						{!user && (
							<NavbarLink href='/login'>Sign in</NavbarLink>
						)}
						{user ? (
							<StyledCustomButton
								color='primary'
								variant='contained'
								size='small'
								labelStyles={{ fontSize: '15px' }}
								borderRadius={100}
								href='/'>
								Dashboard
							</StyledCustomButton>
						) : (
							<StyledCustomButton
								color='primary'
								variant='contained'
								size='small'
								labelStyles={{ fontSize: '15px' }}
								borderRadius={100}
								href='/signup'>
								Sign Up
							</StyledCustomButton>
						)}
					
					</Stack>
				</Stack>
			</Box>
		</Toolbar>
	);
}
