import PageLinkComponent from '@/components/page/linkComponent';
import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';

export default function NavbarButton( { sx, btnColor, ...props }: {
	btnColor?: string
} & ButtonProps<typeof PageLinkComponent> ) {
	return (
		<Button
			sx={{
				'borderRadius': 25,
				'bgcolor'     : btnColor,
				':hover'      : btnColor && {
					filter         : 'brightness(110%)',
					backgroundColor: btnColor,
				},
				...sx,
			}}
			component={PageLinkComponent}
			{...props}
		/>
	);
}
