import { isProduction } from '@/utils/config';
import {
	AccountBalanceRounded as AccountBalanceRoundedIcon,
	Instagram as InstagramIcon,
	LinkedIn as LinkedInIcon,
	QrCodeScanner as QrCodeScannerIcon,
	ReceiptRounded as ReceiptRoundedIcon,
	ShoppingBagOutlined as ShoppingBagOutlinedIcon,
	ShoppingBasket as ShoppingBasketIcon,
} from '@mui/icons-material';
import type { ReactNode } from 'react';

export type NavbarLinkProps = {
	href: string,
	name?: string,
	target?: string,
	linkColor?: string,
	icon?: ReactNode,
	description?: string,
	routes?: NavbarLinkProps[]
};

export const navbarLinks: NavbarLinkProps[] = [ {
	name  : 'Products',
	href  : '#',
	routes: [ {
		href       : '/products/invoicing-estimating',
		name       : 'Invoicing & Estimating',
		description: 'Create and send invoices, estimates, quotes and more',
		icon       : <ReceiptRoundedIcon/>,
	}, {
		href       : '/products/purchasing',
		name       : 'Vendor Purchasing',
		description: 'Create purchase orders and manage vendors',
		icon       : <ShoppingBagOutlinedIcon/>,
	}, {
		href       : '/products/house-accounts',
		name       : 'House Accounts',
		description: 'Manage customer accounts and invoice routine customers',
		icon       : <AccountBalanceRoundedIcon/>,
	}, {
		href       : '/products/inventory-management',
		name       : 'Inventory Management',
		description: 'Manage your inventory and stock levels',
		icon       : <QrCodeScannerIcon/>,
	}, ...!isProduction ? [ {
		href       : '/products/online-store',
		name       : 'Ecommerce & Online Stores',
		description: 'Create and manage online stores and sell products',
		icon       : <ShoppingBasketIcon/>,
	} ] : [] ],
}, {
	href: '/pricing',
	name: 'Pricing',
}, {
	href: '/contact',
	name: 'Contact',
}, {
	href: '/partners',
	name: 'Partners',
}, {
	href: '/install',
	name: 'Install',
}, {
	href     : 'https://calendly.com/invoiss/invoiss-introduction',
	name     : 'Schedule a Demo',
	target   : '_blank',
	linkColor: 'success.main',
} ];

export const footerLinks: NavbarLinkProps[] = [ {
	href  : '',
	name  : 'Resources',
	routes: [
		{ href: 'https://docs.invoiss.com', name: 'API' },
		{ href: '/pricing', name: 'Pricing' },
		{ href: '/install', name: 'Install' },
		{ href: '/help', name: 'Help' },
		{ href: 'https://status.invoiss.com', name: 'Status' },
		{ href: '/privacy', name: 'Privacy Policy' },
		{ href: '/terms', name: 'Terms of Use' },
	],
}, {
	href  : '',
	name  : 'Company',
	routes: [
		{ href: '/about', name: 'About' },
		{ href: '/contact', name: 'Contact' },
		{ href: '/partners', name: 'Partners' },
		{ href: '/blog', name: 'Blog' },
	],
}, {
	href  : '',
	name  : 'Connect',
	routes: [
		{ href: '/clover', name: 'Clover' },
		{ href: '/quickbooks', name: 'Quickbooks' },
		{ href: '#.', name: 'Gmail' },
		{ href: '#.', name: 'Outlook' },
		{ href: '#.', name: 'Yahoo' },
	],
}, {
	href  : '',
	name  : 'Social',
	routes: [
		{
			name  : 'Instagram',
			href  : 'https://www.instagram.com/invoiss.app/',
			icon  : InstagramIcon,
			target: '_blank',
		}, {
			name  : 'LinkedIn',
			href  : 'https://www.linkedin.com/company/invoiss',
			icon  : LinkedInIcon,
			target: '_blank',
		},
	],
} ];
