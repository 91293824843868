import { Box, IconButton } from '@mui/material';
import type { SetStateAction } from 'react';

export default function MenuButton( { showNavbar, setShowNavbar }: {
	showNavbar: boolean,
	setShowNavbar: ( value: SetStateAction<boolean> ) => void
} ) {
	return (
		<IconButton sx={{ p: 1 }} onClick={() => setShowNavbar( !showNavbar )}>
			<Box>
				<Box
					sx={{
						width     : 20,
						height    : 2,
						bgcolor   : 'text.primary',
						transition: '500ms',
						transform : showNavbar
							? 'translateX(3px) rotate(45deg) translateY(5px)'
							: 'rotate(0)',
					}}
				/>
				<Box
					sx={{
						width     : 20,
						height    : 2,
						mt        : '5px',
						bgcolor   : 'text.primary',
						transition: '500ms',
						transform : showNavbar
							? 'translateX(3px) rotate(-45deg) translateY(-5px)'
							: 'rotate(0)',
					}}
				/>
			</Box>
		</IconButton>
	);
}
